/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sword': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.725 11.525a.932.932 0 00-1.023-.203.932.932 0 00-.303.203l-.272.272-1.762-1.763-.189-.188.943-.947 1.668-1.67a.947.947 0 000-1.33.945.945 0 00-1.33 0L11.433 6.92 5.069.552A1.838 1.838 0 003.75 0H1.876A1.89 1.89 0 00.55.552 1.871 1.871 0 000 1.875V3.75a1.84 1.84 0 00.553 1.323l6.365 6.368-1.022 1.022a.937.937 0 00.663 1.605c.25 0 .491-.097.668-.273l1.669-1.67.942-.947.189.188 1.77 1.76-.271.272A.936.936 0 0012.188 15a.938.938 0 00.663-.275l.924-.924s.012-.006.016-.01a.1.1 0 00.01-.018l.925-.923a.936.936 0 00-.002-1.324zM1.876 3.748V1.872h1.875l6.364 6.368-1.874 1.876-6.365-6.368z" _fill="#898B9B"/>'
  }
})
